
























import { ClientEvent, InputType } from '@jovotech/client-web-vue2';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'chat-widget-window-footer',
  components: {},
})
export default class ChatWidgetWindowFooter extends Vue {
  inputValue = '';

  mounted() {
    this.$client.on(ClientEvent.Request, this.onRequest);
  }

  beforeDestroy() {
    this.$client.off(ClientEvent.Request, this.onRequest);
  }

  focusTextInput() {
    (this.$refs.textInput as HTMLInputElement).focus();
  }

  async sendText() {
    if (!this.inputValue) return;
    const text = this.inputValue;
    this.inputValue = '';
    return this.$client.send({
      type: InputType.Text,
      text,
    });
  }

  private onRequest() {
    this.focusTextInput();
  }
}
