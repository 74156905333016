











































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'chat-widget-toggle-button',
  components: {},
})
export default class ChatWidgetToggleButton extends Vue {
  @Prop({ required: true, type: Boolean })
  isActive!: boolean;
}
