











import ChatWidgetWindowBody from '@/components/window/ChatWidgetWindowBody.vue';
import ChatWidgetWindowFooter from '@/components/window/ChatWidgetWindowFooter.vue';
import ChatWidgetWindowHeader from '@/components/window/ChatWidgetWindowHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'chat-widget-window',
  components: { ChatWidgetWindowHeader, ChatWidgetWindowBody, ChatWidgetWindowFooter },
})
export default class ChatWidgetWindow extends Vue {
  focusTextInput() {
    // eslint-disable-next-line
    (this.$refs.bottom as any).focusTextInput();
  }
}
