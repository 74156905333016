





























import ChatWidgetToggleButton from '@/components/ChatWidgetToggleButton.vue';
import ChatWidgetWindow from '@/components/window/ChatWidgetWindow.vue';
import { ClientEvent, InputType, NormalizedOutputTemplate } from '@jovotech/client-web-vue2';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'chat-widget',
  components: { ChatWidgetWindow, ChatWidgetToggleButton },
})
export default class ChatWidget extends Vue {
  isVisible = false;

  async mounted() {
    this.$client.on(ClientEvent.Output, this.onOutput);
  }

  async handleToggle() {
    if (!this.$client.isInitialized) {
      await this.$client.initialize();
      await this.$client.send({ type: InputType.Launch });
    }
    this.isVisible = !this.isVisible;
    await this.$nextTick();
    if (this.isVisible) {
      // eslint-disable-next-line
      (this.$refs.window as any).focusTextInput();
    }
  }

  private onOutput(output: NormalizedOutputTemplate) {
    // custom property just used for this example to redirect to a given website
    const redirectTo = output.platforms?.web?.redirectTo;
    if (typeof redirectTo === 'string' && redirectTo) {
      setTimeout(() => {
        window.open(redirectTo, '_blank');
      }, 800);
    }
  }
}
