



















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'chat-widget-window-header',
  components: {},
})
export default class ChatWidgetWindowHeader extends Vue {}
