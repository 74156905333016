






import ChatWidget from '@/components/ChatWidget.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { ChatWidget },
})
export default class App extends Vue {}
