import JovoWebClientVue, { JovoWebClientVueConfig } from '@jovotech/client-web-vue2';
import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use<JovoWebClientVueConfig>(JovoWebClientVue, {
  endpointUrl: process.env.VUE_APP_CLIENT_ENDPOINT_URL || 'https://webhook.jovo.cloud/58332d53-3e3d-479f-abeb-34f9c0a6c247',
  config: {
    output: {
      audioPlayer: {
        enabled: false,
      },
      speechSynthesizer: {
        enabled: false,
      },
      reprompts: {
        enabled: false,
      },
    },
    store: {
      shouldPersistSession: false,
    },
  },
});

new Vue({
  render: (h) => h(App),
}).$mount('#app');
